import { defineStore } from "pinia";

export const useLoginModalStore =  defineStore('loginStore',{
  state: () => ({
    showModal: false
  }),
  actions: {
    toggleLoginModal() {
      this.showModal = !this.showModal;
    },
    showLoginModal() {
      this.showModal = true
    },
    hideLoginModal() {
      this.showModal = false
    }
  }
});
