import { useContactSessionStore } from '@session-components/session-store.js'
import { useLoginModalStore } from "@stores/login";
import { mapState, mapActions } from 'pinia'

const object = {
  inject: ['$axios', '$controller'],
  computed: {
    ...mapState(useContactSessionStore, [
      'contactHasSession',
      'contact',
      'loadingContactSession',
      'contactName',
      'contactMail',
      'verified'
    ]),
  },
  methods: {
    ...mapActions(useContactSessionStore, [
      'setLoading',
      'unsetLoading',
      'setSession',
      'unsetSession',
      'setContactData'
    ]),
    ...mapActions(useLoginModalStore, ['showLoginModal']),
    searchContactSession() {
      this.setLoading()
      this.$axios.get('/contact-has-session')
          .then((response) => {
            this.setSession()
            this.setContactData({
              contact: response.data.contact.data,
              verified: response.data.verified // Pass verified status
            })
          })
          .catch(() => {
            this.unsetSession()
          })
          .finally(() => {
            this.unsetLoading()
          })
    },
    logout() {
      this.setLoading();
      document.cookie = 'mints_contact_id' + '=; Max-Age=0; path=/; domain=' + window.location.hostname;
      this.$axios.get('/perfil/cerrar-sesion')
          .then(() => {
            window.location.href = '/'
          })
          .catch((e) => {
            console.log(e);
            window.location.href = '/';  // Redirect on failure as a fallback
          })
          .finally(() => {
            this.searchContactSession();
            this.unsetLoading();
          });
    },
  }
}

export default object
