import { defineStore } from 'pinia'

export const useContactSessionStore = defineStore('contactSession', {
  state: () => ({
    contactHasSession: false,
    contact: null,
    loadingContactSession: true,
    contactName: null,
    contactMail: null,
    verified: false
  }),
  getters: {
    changedLoading() {
      return this.loadingContactSession
    }
  },
  actions: {
    setLoading() {
      this.loadingContactSession = true
    },
    unsetLoading() {
      this.loadingContactSession = false
    },
    setSession() {
      this.contactHasSession = true
    },
    unsetSession() {
      this.contactHasSession = false
      this.verified = false
    },
    setContactData(data) {

      this.contactName = data.contact.full_name
      this.contactMail = data.contact.email
      this.verified = data.verified
    }
  }
})
